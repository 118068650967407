<template>
  <el-config-provider :locale="elementLocale" :zIndex="3000">
    <DpApplicationContainer v-if="isAuth" :menuItems="menuItems">
      <ReminderSelectCompany v-if="!companyStore.hasSelectedCompany"/>
      <router-view/>
    </DpApplicationContainer>
  </el-config-provider>
</template>

<script setup>
import {onBeforeMount, computed, defineAsyncComponent} from "vue";
import { ElConfigProvider } from 'element-plus';

import {menuItems} from './pages.js';

import {useAuthStore, useCompanyStore, useEnvStore} from "@dutypay/store-modules";
const authStore = useAuthStore()
const envStore = useEnvStore()
const isAuth = computed(() => {
  return authStore.status === 'success'
})
const companyStore = useCompanyStore();
const ReminderSelectCompany = defineAsyncComponent(() => import('@dutypay/settings-pages/src/reminder/ReminderSelectCompany'))

import {useI18n} from "vue-i18n";
const { locale } = useI18n({useScope: 'global'})
import elementLocales from '@dutypay/components-library/src/locales/element-plus'
const elementLocale = computed(() => {
  return elementLocales[locale.value];
})

async function initApp() {
  envStore.setGlobalLoading()
  await envStore.fetchHosts()
  await authStore.tokenAuth()
  await envStore.getDebug()
  await companyStore.initCompanyStore()
  envStore.releaseGlobalLoading()
}

onBeforeMount(async () => {
  await initApp()
})
</script>

<style lang="scss">
@import '~@dutypay/design-system/dist/dutypay-design-system.css';
@import '~@dutypay/settings-pages/dist/dutypay-settings-pages.css';
$--color-primary: #5392bc;
@import '~@dutypay/design-system/src/scss_legacy/index.scss'; // TODO: Legacy scss. Can be removed when the last relicts of element are gone.
</style>

<i18n>
{}
</i18n>
